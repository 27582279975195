import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
import Services from "./index/Services"
import LearnMoreCards from "./index/LearnMoreCards"
// import ImageTexts from "../components/ImageTexts"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Section } from "../components/Containers"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import ProgramToast from "../components/program-toast/ProgramToast"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = this.props.data.allReviews.nodes

    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <Layout
        className={`main-homepage ${language === "es" ? "es" : null}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <div className="static-container" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              language={language}
            />
          )}

          {language == "en" && (
            <>
              <TestimonialGrid language={language} reviews={allReviews} />

              <div className="columns">
                {post.featuredReviews.map(card => (
                  <div key={card.youtube} className="column">
                    <TestimonialPreview
                      language={language}
                      youtube={card.youtube}
                      image={card.image}
                      heading={card.heading}
                      subheading={card.subHeading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <LearnMoreCards language={language} post={post.learnMoreCards} />

          <ImageText language={language} post={post} textImage noReverse />

          <Services language={language} post={post.services} />

          {/* <FixedFacewallHomepage
              language={language}
              heading={
                language === "es"
                  ? "Escuche Acerca de Nuestros Pacientes"
                  : "Hear From Our Patients"
              }
              reviews={fixedFacewallReviews}
            /> */}

          <InTheCommunity
            colorBack
            language={language}
            data={post.inTheCommunity}
          />

          {/* <HeadingVideo post={post} /> */}

          <ReferringProvider
            colorBack
            textOnly
            language={language}
            data={post.referringProvider}
          />

          <FirstTime colorBack language={language} post={post} noReverse />

          {post.homeRatingsReviews.hasThisSection && (
            <Section>
              <AllRatingsReviews
                animation
                heading={post.homeRatingsReviews.heading}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            </Section>
          )}
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
            button {
              title
              href
            }
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        toast {
          hasThisSection
          text
          closeDate
        }
      }
    }
  }
`

export default IndexPage
